import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Skeleton, Colors, Headline2, Body2, Body1 } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "skeleton"
    }}>{`Skeleton`}</h1>
    <p>{`Skeletons provide a loading state that mimics the shape of your content.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Skeleton} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Skeleton>FFFFF</Skeleton>\n<br />\n<Skeleton count={5}></Skeleton>\n<br />\n<Skeleton count={20} color={Colors.orange500} />\n<br />\n<Headline2>\n  <Skeleton rounded={false}>Hello World</Skeleton>\n</Headline2>\n<br />\n<Body1>\n  <Skeleton>\n    Heollo <br />\n    World\n  </Skeleton>\n</Body1>\n<Body2>\n  <Skeleton>\n    Heollo <br />\n    World\n  </Skeleton>\n</Body2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Skeleton,
      Colors,
      Headline2,
      Body2,
      Body1,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Skeleton mdxType="Skeleton">FFFFF</Skeleton>
  <br />
  <Skeleton count={5} mdxType="Skeleton"></Skeleton>
  <br />
  <Skeleton count={20} color={Colors.orange500} mdxType="Skeleton" />
  <br />
  <Headline2 mdxType="Headline2">
    <Skeleton rounded={false} mdxType="Skeleton">Hello World</Skeleton>
  </Headline2>
  <br />
  <Body1 mdxType="Body1">
    <Skeleton mdxType="Skeleton">
      Heollo <br />
      World
    </Skeleton>
  </Body1>
  <Body2 mdxType="Body2">
    <Skeleton mdxType="Skeleton">
      Heollo <br />
      World
    </Skeleton>
  </Body2>
    </Playground>
    <h2 {...{
      "id": "width--height"
    }}>{`Width & Height`}</h2>
    <Playground __position={2} __code={'<Skeleton width={80} />\n<br />\n<Skeleton width={30} height={80} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Skeleton,
      Colors,
      Headline2,
      Body2,
      Body1,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Skeleton width={80} mdxType="Skeleton" />
  <br />
  <Skeleton width={30} height={80} mdxType="Skeleton" />
    </Playground>
    <p><strong parentName="p">{`width`}</strong>{` 또는 `}<strong parentName="p">{`hegiht`}</strong>{`가 있다면 `}<inlineCode parentName="p">{`display: block`}</inlineCode>{`으로 변합니다.`}</p>
    <h2 {...{
      "id": "circle"
    }}>{`Circle`}</h2>
    <Playground __position={3} __code={'<Skeleton width={80} height={80} circle />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Skeleton,
      Colors,
      Headline2,
      Body2,
      Body1,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Skeleton width={80} height={80} circle mdxType="Skeleton" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      